<template>
  <div class="messageCenter">
    <div class="message_header">
      <p class="p_title">我的消息</p>
      <div class="operation">
        <p @click="Read" v-if="this.show.delShow">
          <i class="el-icon-check"></i>
          <span>一键已读</span>
        </p>
        <p v-if="this.show.delShow" @click="showDel">
          <i class="el-icon-delete"></i>
          <span>删除</span>
        </p>
        <p
          v-if="!this.show.delShow"
          style="
            border: 1px solid #4673ff;
            box-sizing: border-box;
            color: #4673ff;
          "
          @click="showClose"
        >
          取消
        </p>
        <p
          v-if="!this.show.delShow"
          style="background-color: #4673ff; color: #fff"
          @click="showFinish"
        >
          完成
        </p>
      </div>
    </div>

    <div v-if="this.show.delShow" class="message_main">
      <el-collapse
        v-model="activeNames"
        accordion
        v-for="item in list"
        :key="item.id"
        @change="handleChange(item.id)"
      >
        <el-collapse-item :name="item.rank">
          <template slot="title">
            {{ item.title }}&emsp;
            <i class="read" v-show="item.unread"></i>
            <i
              class="read"
              style="background-color: transparent"
              v-show="!item.unread"
            ></i>
            <i style="margin-left: 2.5rem; color: #a4abc0">
              {{ item.time }}
            </i>
          </template>
          <div class="message_info">
            {{ item.main }}
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="empty" v-if="this.list.length === 0">
        <el-empty description="暂无消息~"></el-empty>
      </div>
    </div>

    <div v-if="!this.show.delShow" class="message_del">
      <div class="delList" v-for="(item, index) in list" :key="index">
        <div class="list_item">
          <input
            type="checkbox"
            :checked="!item.unread"
            @click="checkOne(item.id)"
            name=""
            id=""
          />
          <div class="item_info">
            <p>
              <i :class="item.unread ? '' : 'read'"></i>
              {{ item.title }}
            </p>
            <p>{{ item.time }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      activeNames: "",
      list: [],
      //
      checkList: [],
      show: {
        delShow: true,
      },
    };
  },
  methods: {
    handleChange(val) {
      // console.log(val);
      // console.log(this.list);
      this.list.map((item) => {
        if (item.id === val) {
          item.unread = false;
        }
      });
    },
    Read() {
      this.list.map((item) => {
        item.unread = false;
      });
      this.$store.state.infoList.map((item) => {
        item.unread = false;
      });
    },

    showDel() {
      if (this.list.length) {
        this.show.delShow = false;
        this.checkList = [];
        this.list.map((item) => {
          if (!item.unread) {
            this.checkList.push(item.id);
          }
        });
        // console.log(this.checkList);
      } else {
        alert("暂无消息~");
      }
    },
    showFinish() {
      this.show.delShow = true;
      // console.log(this.checkList);
      this.checkList.map((item) => {
        this.list = this.list.filter((t) => t.id !== item);
      });
      this.$store.commit("infoList", { ...this.list });
    },
    showClose() {
      this.show.delShow = true;
    },
    checkOne(id) {
      if (this.checkList.findIndex((v) => v === id) !== -1) {
        let index = this.checkList.findIndex((v) => v === id);
        this.checkList.splice(index, 1);
      } else {
        this.checkList.push(id);
      }
    },
  },
  created() {
    // this.list = this.$store.state.infoList;
    let List = this.$store.state.infoList; 
    let newList = []
    for(let i in List){
      newList.push(List[i]) 
    }
    newList.map((item) => {
      if (!item.unread) {
        newList = newList.filter((item1) => item1 != item);
        newList.push(item);
      }
    });
    newList.forEach((item, index) => {
      item.rank = index + "";
    });
    this.list = newList;
  },
  mounted() {},
  watch: {},
};
</script>
<style lang='scss' scoped>
.messageCenter {
  width: 100%;
  box-sizing: border-box;
  padding: 1.875rem 2.1875rem;
  .message_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.1875rem;
    .p_title {
      font-size: 1.5rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #191f33;
      line-height: 2.0625rem;
    }
    .operation {
      display: flex;
      justify-content: space-between;
      p {
        cursor: pointer;
        margin-left: 1rem;
        height: 2rem;
        border-radius: 0.25rem;
        border: 0.0625rem solid #ebedf2;
        box-sizing: border-box;
        padding: 0.375rem 0.9375rem;
        display: flex;
        justify-content: space-between;
        span {
          flex: 1;
        }
        i {
          margin-right: 0.5rem;
          line-height: 1.25rem;
          text-align: center;
        }
      }
    }
  }
  .message_main {
    .read {
      display: inline-block;
      background-color: red;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 50%;
    }
    .message_info {
      background-color: #f5f6fc;
      color: #a4abc0;
      min-height: 5.625rem;
      text-align: left;
      border-radius: 0 0 0.5rem 0.5rem;
      box-sizing: border-box;
      padding: 1.125rem 3.125rem;
    }
  }
}

.message_del {
  .delList {
    .list_item {
      height: 100%;
      // background-color: springgreen;
      display: flex;
      height: 3.75rem;
      margin: 0 0 1rem 0;
      input {
        width: 1.5rem;
        height: 1.5rem;
        margin: auto 1.6875rem;
      }
      .item_info {
        flex: 1;
        display: flex;
        justify-content: space-between;
        // box-sizing: border-box;
        padding: 1.0625rem 0;
        border-radius: 0.5rem;
        border: 0.0625rem solid #ebedf2;
        p {
          // line-height: 3.75rem;
          font-size: 1.125rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #191f33;
          i {
            display: inline-block;
            width: 0.625rem;
            height: 0.625rem;
            border-radius: 50%;
            background-color: red;
            margin: 0 1rem 0 1.5625rem;
          }
          .read {
            background-color: transparent;
          }
        }
        p:nth-child(2n) {
          color: #a4abc0;
          padding-right: 1.5625rem;
        }
      }
    }
  }
}
</style>
